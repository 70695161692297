<template>
    <zw-sidebar @shown="shown" :title="$t('hotel.title.hotel')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-card :header="$t('article.group.basic')">
                    <b-card-text>
                        <b-row>
                            <b-col sm="3">
                                <zw-input-group v-model="form.name"
                                                name="name"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col sm="3">
                                <zw-input-group v-model="form.sku"
                                                name="sku"
                                                :label-prefix="labelPrefix"
                                                validate="required"
                                ></zw-input-group>
                            </b-col>

                            <b-col sm="3">
                                <zw-select-group v-model="form.position"
                                                 :options="getPositions() | optionsVV"
                                                 name="position"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col sm="3">
                                <zw-select-group v-model="form.stars"
                                                 :options="getStars() | optionsVV"
                                                 name="stars"
                                                 :label-prefix="labelPrefix"
                                                 validate="required"
                                ></zw-select-group>
                            </b-col>

                            <b-col sm="3">
                                <zw-input-group v-model="form.phone"
                                                name="phone"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>

                            <b-col sm="3">
                                <zw-input-group v-model="form.email"
                                                name="email"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>

                            <b-col sm="12">
                                <zw-ckeditor v-model="form.description"></zw-ckeditor>
                            </b-col>

                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.address')">
                    <b-card-text>
                        <b-row>
                            <b-col sm="3">
                                <zw-input-group v-model="form.street"
                                                name="street"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.house_nr"
                                                name="house_nr"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.zip"
                                                name="zip"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.city"
                                                name="city"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.location"
                                                name="location"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.country"
                                                name="country"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                            <b-col sm="3">
                                <zw-input-group v-model="form.region"
                                                name="region"
                                                :label-prefix="labelPrefix"
                                ></zw-input-group>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.childs')">
                    <b-card-text>
                        <b-row v-if="form.customValues['children_discounts'].length">
                            <b-col cols="1">{{ $t('article.label.age_discounts.age_from') }}</b-col>
                            <b-col cols="1">{{ $t('article.label.age_discounts.age_to') }}</b-col>
                            <b-col cols="2">{{ $t('article.label.age_discounts.type') }}</b-col>
                            <b-col cols="1">{{ $t('article.label.age_discounts.value') }}</b-col>
                        </b-row>

                        <child-discount-line v-for="(row,index) in form.customValues['children_discounts']"
                                             :key="index"
                                             v-model="form.customValues['children_discounts'][index]"
                                             :remove="()=>{removeDiscountLine(index)}"
                        ></child-discount-line>

                        <b-row>
                            <b-col cols="1">
                                <b-button variant="primary" @click="addDiscountLine">
                                    <font-awesome-icon icon="plus"/>
                                </b-button>
                            </b-col>
                        </b-row>


                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.food')">
                    <b-card-text>
                        <b-row>
                            <b-col cols="3">
                                <zw-select-group v-model="form.customValues['required_food']"
                                                 :options="foodArticles"
                                                 name="required_food"
                                                 :label-prefix="labelPrefix"
                                                 text-field="name"
                                                 value-field="id"
                                                 multiple
                                ></zw-select-group>
                            </b-col>

                            <b-col cols="3">
                                <custom-field v-model="form.customValues['optional_food_start']"
                                              :field="form.customFields['optional_food_start']"
                                              format="DD/MM"
                                ></custom-field>
                            </b-col>
                            <b-col cols="3">
                                <custom-field v-model="form.customValues['optional_food_till']"
                                              :field="form.customFields['optional_food_till']"
                                              format="DD/MM"
                                ></custom-field>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('article.group.options')">
                    <b-card-text>
                        <b-form-checkbox-group
                            v-model="form.customValues['options']"
                        >
                            <b-row>
                                <b-col sm="3" v-for="option in form.customFields['options'].options" :key="option">
                                    <b-form-checkbox
                                        :value="option"
                                    > {{ option }}
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-form-checkbox-group>
                    </b-card-text>
                </b-card>

                <b-card :header="$t('hotel.label.photos')">
                    <b-card-text>
                        <zw-files-upload-group v-model="form.images"
                                               ref="images"
                                               url="/hotels/upload"
                                               accepted-files="image/*"
                        ></zw-files-upload-group>
                    </b-card-text>
                </b-card>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ChildDiscountLine from "./components/ChildDiscountLine";

export default {
    components: {ChildDiscountLine},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                images: [],
            },
            form: {},
            labelPrefix: 'hotel.label.',
            foodArticles: [],
        }
    },
    methods: {
        ...mapGetters('CommonData', ['getSettings']),
        ...mapGetters('Hotels', ['getHotel', 'getPositions', 'getStars']),
        shown() {
            this.$store.dispatch('Articles/fetchCategoryArticles', this.getSettings()['food_category']).then((response) => {
                this.foodArticles = []
                response.data.articles.forEach(article => {
                    if (article.customValues['hotel'] == this.payload.id) {
                        this.foodArticles.push(article)
                    }
                })

                this.$store.dispatch('Hotels/fetchHotel', {
                    id: this.payload.id ? this.payload.id : 0,
                })
                    .then(() => {
                        this.form = {
                            ...JSON.parse(JSON.stringify(this.defaultForm)),
                            ...JSON.parse(JSON.stringify(this.getHotel()))
                        }

                        this.form.customValues['options'] = JSON.parse(this.form.customValues['options']) || []
                        this.form.customValues['children_discounts'] = JSON.parse(this.form.customValues['children_discounts']) || []
                        this.form.customValues['required_food'] = JSON.parse(this.form.customValues['required_food']) || []

                        this.$refs['observer'].reset()
                    })
                    .finally(() => {
                        this.loading = false
                    })
            })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    // These needed for remove old options
                    let options = []
                    Object.values(this.form.customFields['options'].options).forEach(option => {
                        if (this.form.customValues['options'].includes(option)) {
                            options.push(option)
                        }
                    })

                    this.form.customValues['options'] = options

                    this.$store.dispatch('Hotels/saveHotel', this.form)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
        addDiscountLine() {
            this.form.customValues['children_discounts'].push({})
        },
        removeDiscountLine(index) {
            this.form.customValues['children_discounts'] = this.form.customValues['children_discounts'].filter((data, i) => {
                return i != index
            })
        },
    }
}
</script>